import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CompaignTable from "./CompaignTable";
import withMainLayout from "./../HOC/withMainLayout";
import { Box, Typography } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Index = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="page-content">
      {/* <div className="container"> */}
      {/* <div className="card"> */}
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          indicatorColor="#00000000"
          style={{ border: "none" }}
        >
          <Tab
            label="Bounty Program"
            style={{ color: "#000000", border: "none", outline: "none" }}
            disabled
            {...a11yProps(0)}
          />
          {/* <Tab label="Requested Bounties" {...a11yProps(1)} /> */}
        </Tabs>

        <TabPanel value={value} index={0}>
          <CompaignTable />
        </TabPanel>
      </Box>
      {/* <Tabs defaultActiveKey="btc">
            <Tab eventKey="btc" title="Bounty Compaign">
              <CompaignTable />
            </Tab>
            <Tab eventKey="ethereum" title="Requested Bounties">
              <RequestedBounty />
            </Tab>
          </Tabs> */}
      {/* </div> */}
      {/* <!-- .card --> */}
      {/* </div> */}
      {/* <!-- .container --> */}
    </div>
    // <!-- .page-content -->
  );
};

export default withMainLayout(Index);
