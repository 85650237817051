export const getHoursMinutes = (t) => {
  let dt = new Date(t);
  let h = dt.getHours();
  let m = dt.getMinutes();
  m = m.toString();
  m = m.length < 2 ? '0' + m : m;
  return h > 12
    ? h - 12 + ':' + m + ' PM'
    : h === 12
    ? h + ':' + m + ' PM'
    : h + ':' + m + ' AM';
};
const RecentUsers = ({ users }) => {
  const time = (t) => {
    return new Date(t);
  };

  const recentUsers = users
    .sort((a, b) => (time(b?.updated_at) > time(a?.updated_at) ? 1 : -1))
    .slice(0, 5);

  // const getHoursMinutes = (t) => {
  //   let dt = new Date(t);
  //   let h = dt.getHours();
  //   let m = dt.getMinutes();
  //   return h > 12 ? h - 12 + ":" + m + " PM" : h + ":" + m + " AM";
  // };
  const color = (i) => {
    if (i % 2) {
      return { backgroundColor: '#87CEFA' };
    } else if (i % 3) {
      return { backgroundColor: '#3CB371' };
    } else {
      return { backgroundColor: '#FFA07A' };
    }
  };
  return (
    <div className='col-xl-4 col-lg-5'>
      <div className='token-calculator card card-full-height'>
        <div className='card-innr b-cardborderbottom'>
          <div
            className='card-head'
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <h4 className='card-title'>Recent Users</h4>
            <a href='#'>
              User List<em className='fas fa-angle-right ml-2'></em>
            </a>
          </div>
          <div className='d-flex b-padding-left '>
            <p className='b-roundbg'>DA</p>
            <div>
              <p>Debra Alex </p>
              <p> 6215c458add2200016848c9a</p>
            </div>
          </div>

          <div className='d-flex b-padding-left '>
            <p className='b-roundbg b2-color'>DA</p>
            <div>
              <p>Debra Alex </p>
              <p> 6215c458add2200016848c9a</p>
            </div>
          </div>
          <div className='d-flex b-padding-left '>
            <p className='b-roundbg b4-color'>DA</p>
            <div>
              <p>Debra Alex </p>
              <p> 6215c458add2200016848c9a</p>
            </div>
          </div>
          <div className='d-flex b-padding-left'>
            <p className='b-roundbg b3-color'>DA</p>
            <div>
              <p>Debra Alex </p>
              <p> 6215c458add2200016848c9a</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- .card --> */}
    </div>
  );
};

export default RecentUsers;
// {recentUsers.map((u, index) => (
//   <div key={u._id} className='d-flex b-padding-left'>
//     <p className='b-roundbg' style={color(index)}>
//       {(u.first_name + ' ' + u.last_name)
//         .split(' ')
//         .map((n) => n[0]?.toUpperCase())}
//     </p>
//     <div>
//       <p>{u.first_name + ' ' + u.last_name}</p>
//       <p>{u._id}</p>
//     </div>
//   </div>
// ))}
