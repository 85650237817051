//Auth Types
export const LOAD = "LOAD";
//token value
export const GET_TOKEN_VALUE = "GET_TOKEN_VALUE";
export const TRANSACTION_LIST = "TRANSACTION_LIST";

export const REGISTER_MSG = "REGISTER_MSG";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const VERIFY_FAIL = "VERIFY_FAIL";

export const FORGET_MSG = "FORGET_MSG";
export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_FAIL = "FORGET_FAIL";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";
export const SET_AUTH_VERIFICATION = "SET_AUTH_VERIFICATION";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const USER_LOADED_DATA = "USER_LOADED_DATA";
export const AUTH_ERROR_DATA = "AUTH_ERROR_DATA";

export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const ERR_USERS = "ERR_USERS";

export const LOGOUT = "LOGOUT";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERR = "UPDATE_PROFILE_ERR";

export const IS_EMAIL_AUTH_ON_SUCCESS = "IS_EMAIL_AUTH_ON_SUCCESS";
export const IS_EMAIL_AUTH_ON_ERR = "IS_EMAIL_AUTH_ON_ERR";

export const IS_SMS_AUTH_ON_SUCCESS = "IS_SMS_AUTH_ON_SUCCESS";
export const IS_SMS_AUTH_ON_ERR = "IS_SMS_AUTH_ON_ERR";

//Profile Types
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERR = "UPDATE_USER_ERR";

export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERR = "UPDATE_PASSWORD_ERR";

export const ENABLE_2FA_SUCCESS = "ENABLE_2FA_SUCCESS";
export const ENABLE_2FA_ERR = "ENABLE_2FA_ERR";

export const UPDATE_2FA_STATUS_SUCCESS = "UPDATE_2FA_STATUS_SUCCESS";
export const UPDATE_2FA_STATUS_ERR = "UPDATE_2FA_STATUS_ERR";

export const VERIFY_2FA_SUCCESS = "VERIFY_2FA_SUCCESS";
export const VERIFY_2FA_ERR = "VERIFY_2FA_ERR";

export const GET_TIER_STATUS_SUCCESS = "GET_TIER_STATUS_SUCCESS";
export const GET_TIER_STATUS_FAIL = "GET_TIER_STATUS_FAIL";
export const BOUNTY_LIST = "BOUNTY_LIST";
export const CREATE_COMPLIANCE_TIER1_SUCCESS =
  "CREATE_COMPLIANCE_TIER1_SUCCESS";
export const CREATE_COMPLIANCE_TIER1_FAIL = "CREATE_COMPLIANCE_TIER1_FAIL";

export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";

export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_SINGLE_USER_SUCCESS = "GET_SINGLE_USER_SUCCESS";
export const GET_SINGLE_USER_FAIL = "GET_SINGLE_USER_FAIL";

export const GET_KYC_LIST = "GET_KYC_LIST";
export const UPDATE_KYC_LIST = "UPDATE_KYC_LIST";
export const TOKEN_DATA = "TOKEN_DATA";
export const AIR_DROP = "AIR_DROP";
export const SET_CONTRACT_ADDRESS = "SET_CONTRACT_ADDRESS";
