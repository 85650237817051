import React, { useEffect, useState, useRef } from "react";
import logoLight2x from "../../../assets/images/brand-a.png";
import toggle from "../../../assets/images/toggle.png";
import avatar from "../../../assets/images/test2.png";
import browserRoute from "./../../../Routes/browserRoutes";
import { Link, useHistory } from "react-router-dom";
import { logout, tokenData, AirDropData } from "../../../Redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { tokenSymbol } from "../../TokenInfo";

const user = JSON.parse(localStorage.getItem("userDetails"));
function useOutsideAlerter(setShowDropdown, ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown({
          profile: false,
          calculator: false,
          language: false,
          time: false,
        });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const TopBar = () => {
  const dropdown = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenInfo = useSelector((state) => state.auth.tokenData);
  const contractAddress = useSelector((state) => state.auth.contractAddress);
  const user = useSelector((state) => state.auth.singleUser);
  console.log("User data", user);
  useEffect(() => {
    if (contractAddress !== "") {
      dispatch(tokenData(contractAddress));
      dispatch(AirDropData());
    } else if (localStorage.getItem("contractAddress")) {
      dispatch(tokenData(localStorage.getItem("contractAddress")));
      dispatch(AirDropData());
    }
  }, [contractAddress]);
  const [showDropdown, setShowDropdown] = useState({
    profile: false,
    calculator: false,
    language: false,
    time: false,
  });

  const [styleClass, setStyleClass] = useState("navbar");
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(history));
  };
  useOutsideAlerter(setShowDropdown, dropdown);
  return (
    <div className="topbar-wrap">
      <div className="topbar is-sticky">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <ul className="topbar-nav d-lg-none">
                <li className="topbar-nav-item relative">
                  {/*   <a className="toggle-nav" href="#">
                    <div
                      className="toggle-icon"
                      onClick={() =>
                        isDrawerActive
                          ? (setStyleClass("navbar"), setIsDrawerActive(false))
                          : (setStyleClass("navbar active"),
                            setIsDrawerActive(true))
                      }
                    >
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                      <span className="toggle-line"></span>
                    </div>
                  </a>*/}
                </li>
              </ul>
            </div>
            <div className="mobile-nav">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-4 col-sm-4 col-4"
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                  >
                    <div>
                      <img src={toggle} width={25} alt="" className="mb-3" />
                      {/* <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span>
                        <span className="toggle-line"></span> */}
                    </div>
                    <div
                      className="offcanvas offcanvas-start b-offcanvaswidth"
                      tabindex="-1"
                      id="offcanvasExample"
                      aria-labelledby="offcanvasExampleLabel"
                    >
                      <div className="b-titlemain">
                        <h5
                          className="offcanvas-title b-title"
                          id="offcanvasExampleLabel"
                        >
                          MEGAICO
                        </h5>
                        <div style={{ textAlign: "end" }}>
                          {" "}
                          <button
                            type="button"
                            className="b-button-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          >
                            {" "}
                            X{" "}
                          </button>
                        </div>
                      </div>
                      <div className="offcanvas-body">
                        <ul className="navbar-nav nav-itempading">
                          <li className="nav-item">
                            <Link
                              className="nav-link active"
                              aria-current="page"
                              to={browserRoute.DASHBOARD}
                            >
                              Dashboard
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={browserRoute.USER_LIST}
                            >
                              User List
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={browserRoute.ICO_DISTRIBUTION}
                            >
                              ICO Distribution
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={browserRoute.TRANSACTIONS}
                            >
                              Transactions
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={browserRoute.BOUNTY_PROGRAM}
                            >
                              Bounty Program
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={browserRoute.AIR_DROP}
                            >
                              Air Drop
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-4 col-4"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <div>
                          <a className="navbar-brand nav-logo" href="#">
                            MEGAICO
                          </a>
                        </div> */}
                  </div>
                  <div
                    className="col-md-4 col-sm-4 col-4"
                    style={{ textAlign: "end" }}
                  >
                    {/* <div className="button-nav">
                          <Link className="login" to={browserRoute.SIGNIN}>
                            Login
                          </Link>
                        </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-4"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a className="main-logo" href="./">
                <h1>MEGAICO</h1>
              </a>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <ul className="topbar-nav">
                <li className="topbar-nav-item relative">
                  {/* <ul className=""> */}
                  {/* <li>
                      <Link
                        to={browserRoute.KYC_LISTS}
                        className="btn btn-sm b-button1 btn-outline btn-light mx-4"
                      >
                        <em className="text-primary ti ti-files"></em>
                        <span>KYC List</span>
                      </Link>
                    </li> */}
                  {/* <li className="d-none">
                      <span className="badge badge-outline badge-success badge-lg">
                        <em className="text-success ti ti-files mgr-1x"></em>
                        <span className="text-success">KYC Approved</span>
                      </span>
                    </li> */}
                  {/* </ul> */}
                  {/* <button className="user-welcome button-color d-none d-lg-inline-block b-button-hovereffect">
                    Connect Wallet
                  </button> */}

                  {showDropdown.profile === true ? (
                    <span
                      className="toggle-tigger user-thumb"
                      // href="#"
                    >
                      <img src={avatar} />
                      <em className="ti ti-user"></em>
                    </span>
                  ) : (
                    <a
                      className="toggle-tigger user-thumb"
                      // href="#"

                      onClick={() => {
                        setShowDropdown({
                          profile: !showDropdown.profile,
                          calculator: false,
                          language: false,
                          time: false,
                        });
                      }}
                    >
                      <img src={avatar} />
                      <em className="ti ti-user"></em>
                    </a>
                  )}
                  <em className="fas fa-angle-right ml-2 b-rotate-icon"></em>

                  {showDropdown.profile && (
                    <div
                      ref={dropdown}
                      id="menu"
                      className="toggle-className dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                    >
                      <>
                        <div className="user-status">
                          <h6 className="user-status-title">Token balance</h6>
                          <div className="user-status-balance">
                            {tokenInfo.totalSupply &&
                              (
                                tokenInfo.totalSupply - tokenInfo.tokenRaised
                              ).toFixed(3)}{" "}
                            <small>{tokenSymbol}</small>
                          </div>
                        </div>
                        <ul className="user-links">
                          <li>
                            <Link to={browserRoute.PROFILE}>
                              <i className="ti ti-id-badge"></i>My Profile
                            </Link>
                          </li>
                          <li>
                            <Link to={browserRoute.ICO_DISTRIBUTION}>
                              <i className="ti ti-infinite"></i>Referral
                            </Link>
                          </li>
                          <li>
                            <Link to={browserRoute.CREATE_TOKEN}>
                              <em className="ti ti-settings"></em>
                              Create Token
                            </Link>
                          </li>
                        </ul>
                        <ul className="user-links bg-light">
                          <li>
                            <Link to="" onClick={onLogout}>
                              <i className="ti ti-power-off"></i>Logout
                            </Link>
                          </li>
                        </ul>
                      </>
                    </div>
                  )}
                </li>{" "}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
