// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_URL = "https://megaico.herokuapp.com/api";
// export const SERVER_URL = "https://1175-103-125-176-209.ngrok.io/api";
export const SERVER_URL_MAIN = "http://18.116.89.176:5000";

export const auth = {
  SIGNUP: `${SERVER_URL}/v1/auth/signup`,
  VERIFY_EMAIL: `${SERVER_URL}/v1/auth/verify/email`,
  LOGIN: `${SERVER_URL}/v1/auth/login`,
  LOGGEDIN_USER: `${SERVER_URL}/v2/user`,
  FORGET_PASSWORD: `${SERVER_URL}/v1/auth/password/forgot`,
  RESET_PASSWORD: `${SERVER_URL}/v1/auth/password/reset`,

  VERIFY_USER: `${SERVER_URL}/v3/auth/verifyuser`,
  FORGET: `${SERVER_URL}/v3/auth/forgot`,
  VERIFY_FORGET: `${SERVER_URL}/v3/auth/verifyforgot`,
  UPDATE_PASSWORD: `${SERVER_URL}/v3/auth/updatepassword`,

  // google 2f verification
  VERIFY_2F_GOOGLE: `${SERVER_URL}/v1/auth/verify2fa`,
  VERIFY_2F_EMAIL: `${SERVER_URL}/v3/auth/verifyEmailSMSVerification`,
  VERIFY_2F_SMS: `${SERVER_URL}/v3/auth/verifySMSVCode`,

  // update profile data
  UPDATE_PROFILE: `${SERVER_URL}/v3`,

  // is_Verificatrion_on
  IS_EMAIL_AUTH_ON: `${SERVER_URL}/v3/auth/updateEmailVerification`,
  IS_SMS_AUTH_ON: `${SERVER_URL}/v3/auth/updateSMSVerification`,
};

export const profile = {
  SAVE_TRANSACTION: `${SERVER_URL}/v1/dashboard/wallet/purchase/tokens`,
  TRANSACTION_LIST: `${SERVER_URL}/v2/admin/gettransactions`,
  GET_TOKEN_VALUE: `${SERVER_URL}/v2/admin/currency/usd`,
  UPDATE_USER_DETAILS: `${SERVER_URL}/v1/profile/update-user`,
  UPDATE_PASSWORD: `${SERVER_URL}/v1/profile/updatePassword`,
  ENABLE_GOOGLE_AUTH: `${SERVER_URL}/v1/profile/enable2fa`,
  UPDATE_GOOGLE_AUTH_STATUS: `${SERVER_URL}/v1/profile/update2faStatus`,
  LOGGINED_USER_COMPLIANCE_TIER_STATUS: `${SERVER_URL}/v1/compliance/tiers/status`,
  DOWNLOAD_WHITE_PAPER: `${SERVER_URL}/v1/dashboard/whitepaper`,
  GET_BOUNTY_LIST: `${SERVER_URL}/v2/dashbaord/bounty/list`,
  APPROVE_BOUNTY: `${SERVER_URL}/v2/dashboard/bounty/status`,
  AIR_DROP_LIST: `${SERVER_URL}/v2/dashboard/airdrop/list`,
  AIR_DROP_STATUS: `${SERVER_URL}/v2/dashboard/airdrop/status`,
  NEW_TOKEN: `${SERVER_URL}/v2/admin/contract/abi`,
};
export const KYC = {
  CREATE_COMPLIANCE_TIER1: `${SERVER_URL}/v1/compliance/tier1_kyc`,
  GET_COMPLIANCE_KYC_TIER1: `${SERVER_URL}/v2/compliance/tier1/kyc_tier1`,
  GET_COMPLIANCE_KYC_TIER2: `${SERVER_URL}/v2/compliance/tier1/kyc_tier2`,
  GET_KYC_LIST: `${SERVER_URL}/v2/kyc/pending`,
  STATUS_KYC_LIST: `${SERVER_URL}/v2/kyc/update/status`,
};
export const users = {
  GET_ALL_USERS: `${SERVER_URL}/v2/admin/getUsers/KYC`,
  GET_SINGLE_USER_DETAILS: `${SERVER_URL}/v2/get/singleuser/`, //id
  // GET_ALL_USERS: `${SERVER_URL}/v2/investors/all`,
  GET_RECENT_USERS: `${SERVER_URL}/v2/user/getAllRecentUsers`,
};
