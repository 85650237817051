const browserRoute = {
  SIGNIN: "/auth/signin",
  DASHBOARD: "/",
  TERMS: "/terms",
  CREATE_TOKEN: "/createtoken",
  USER_LIST: "/user/list",
  USER_DETAILS: "/user/details/:id",
  USER_DETAILS_BTN: "/user/details/",
  PROFILE: "/profile",
  ICO_DISTRIBUTION: "/icodistribution",
  BOUNTY_PROGRAM: "/bountyprogram",
  AIR_DROP: "/airdrop",
  AIR_DROP_LIST: "/airdroplist",
  TRANSACTIONS: "/transactions",
  TRANSACTION_DETAILS: "/transactions/details",
  KYC_LISTS: "/kyc/lists",
  KYC_DETAILS: "/kyc/details/:id",
  KYC_DETAILS_BTN: "/kyc/details/",
};
export default browserRoute;
