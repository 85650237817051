import React, { useState, useEffect } from "react";
import withMainLayout from "./../HOC/withMainLayout";
import { getTokenValue } from "../../Services/profileServices";
import { useDispatch, useSelector } from "react-redux";
import TokenBalance from "./TokenBalance";
import TokenSaleProgress from "./TokenSaleProgress/TokenSaleProgress";
import PreSaleTokens from "./PreSaleTokens";
import IcoStage from "./ICOStage";
import Referral from "./Referral";
const IcoDistribution = () => {
  const dispatch = useDispatch();
  const valueOfToken = useSelector((state) => state.auth.tokenValue);
  console.log(valueOfToken);
  console.log("valueofdata", valueOfToken.data);
  useEffect(() => {
    console.log("first hit");
    dispatch(getTokenValue());
    setInterval(() => {
      // dispatch(getTokenValue());
      console.log("dispatched");
    }, 600000);
  }, []);

  return (
    <div className="page-content">
      <div className="">
        <div className="row">
          <div className="main-content pl-4 col-lg-9">
            <IcoStage />
            <Referral />
          </div>
          {/* <!-- .col --> */}
          <div className="aside sidebar-right col-lg-3 mt-4">
            <TokenBalance />
            <div className="token-sales">
              <div className="b-background-card">
                <PreSaleTokens />
              </div>
            </div>
            {/* <div className="sap"></div> */}
            <div className="b-background-card">
              <TokenSaleProgress />
            </div>
            {/* <!-- .col --> */}
          </div>
          {/* <!-- .container --> */}
        </div>
        {/* <!-- .container --> */}
      </div>
    </div>
  );
};

export default withMainLayout(IcoDistribution);
