import { tokenSymbol } from '../TokenInfo';
import { getHoursMinutes } from '../Dashboard/RecentUsers';
import { useSelector } from 'react-redux';
import { useState } from 'react';
const Notifications = () => {
  const [tranxType, setTranxType] = useState(false);
  const getTranx = useSelector((state) => state.auth.transactionLists);
  const recentNotify = getTranx;
  if (recentNotify.transaction_type === 'purchase') {
    setTranxType(true);
  }
  return (
    <div className='col-xl-4 col-lg-5'>
      <div className='token-sales card card-full-height'>
        <div className='card-innr'>
          <div className='card-head'>
            <div
              className='card-head'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h4 className='card-title'>Notification</h4>
              <a href='#'>
                {/* View All<em className='fas fa-angle-right ml-2'></em> */}
              </a>
            </div>
          </div>
          <div className='d-flex justify-content-between '>
            <div className='b-border-left'>
              <div className='b-paddinglast-section'>
                <p>Commercial Invoice Submitted</p>
                <p className='b-font-date'>11 JUL 8:10 PM</p>
              </div>
            </div>
            <div className='lastsection-button'>
              <a href=''>Purchase</a>
            </div>
          </div>{' '}
          <div className='d-flex justify-content-between '>
            <div className='b-border-left'>
              <div className='b-paddinglast-section'>
                <p>Commercial Invoice Submitted</p>
                <p className='b-font-date'>11 JUL 8:10 PM</p>
              </div>
            </div>
            <div className='lastsection-button'>
              <a href=''>Purchase</a>
            </div>
          </div>{' '}
          <div className='d-flex justify-content-between '>
            <div className='b-border-left'>
              <div className='b-paddinglast-section'>
                <p>Commercial Invoice Submitted</p>
                <p className='b-font-date'>11 JUL 8:10 PM</p>
              </div>
            </div>
            <div className='lastsection-button'>
              <a href=''>Purchase</a>
            </div>
          </div>{' '}
          <div className='d-flex justify-content-between '>
            <div className='b-border-left'>
              <div className='b-paddinglast-section'>
                <p>Commercial Invoice Submitted</p>
                <p className='b-font-date'>11 JUL 8:10 PM</p>
              </div>
            </div>
            <div className='lastsection-button'>
              <a href=''>Purchase</a>
            </div>
          </div>
          {/* {recentNotify.map((notify) => (
            <div className='d-flex justify-content-between '>
              <div className='b-border-left'>
                <div className='b-paddinglast-section'>
                  <p>{notify.email}</p>
                  <p className='b-font-date'>
                    {' '}
                    {new Date(notify.updated_at)
                      .toString('dddd MMM yyyy')
                      .substring(0, 15)}
                    {' ' + getHoursMinutes(notify.updated_at)}
                  </p>
                </div>
              </div>
              <div className='lastsection-button'>
                <a href=''>{notify.transaction_type}</a>
              </div>
            </div>
          ))} */}
        </div>
      </div>
    </div>
    // <div className='col-lg-6'>
    //   <div className='card card-timeline card-full-height'>
    //     <div className='card-innr'>
    //       <div className='card-head has-aside'>
    //         <h4 className='card-title'>Notifications</h4>
    //       </div>
    //       <div className='timeline-wrap' id='timeline-notify'>
    //         <div data-simplebar='init'>
    //           <div className='timeline-innr '>
    //             <div className='timeline mx-4'>
    //               <div className='timeline-line'></div>
    //               {recentNotify.map((notify) => (
    //                 <div
    //                   className={` ${
    //                     tranxType ? 'timeline-item' : 'timeline-item success'
    //                   }`}
    //                 >
    //                   <div className='timeline-time'>
    //                     {new Date(notify.updated_at)
    //                       .toString('dddd MMM yyyy')
    //                       .substring(0, 15)}
    //                     {' ' + getHoursMinutes(notify.updated_at)}
    //                   </div>
    //                   <div className='timeline-content'>
    //                     <p>
    //                       {notify.email}{' '}
    //                       <span className='mx-2'>
    //                         {notify.transaction_type}
    //                       </span>
    //                     </p>
    //                   </div>
    //                 </div>
    //               ))}

    //               <div className='timeline-item hidden success'>
    //                 <div className='timeline-time'>03:45 PM</div>
    //                 <div className='timeline-content'>
    //                   <h5 className='timeline-content-title'>
    //                     Get bonus - 15% of the value of your contribution
    //                   </h5>
    //                   <span className='timeline-content-info'>
    //                     Latest Offers
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className='timeline-item hidden light'>
    //                 <div className='timeline-time'>02:30 PM</div>
    //                 <div className='timeline-content'>
    //                   <p>
    //                     Each member have a unique {tokenSymbol} referral link to
    //                     share with friends and family and receive a bonus - 15%
    //                     of the value of their contribution.
    //                   </p>
    //                   <span className='timeline-content-info'>
    //                     Announcements
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className='timeline-item hidden light'>
    //                 <div className='timeline-time'>02:30 PM</div>
    //                 <div className='timeline-content'>
    //                   <p>
    //                     Each member have a unique {tokenSymbol} referral link to
    //                     share with friends and family and receive a bonus - 15%
    //                     of the value of their contribution.
    //                   </p>
    //                   <span className='timeline-content-info'>
    //                     Announcements
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className='timeline-item hidden danger'>
    //                 <div className='timeline-time'>12:17 AM</div>
    //                 <div className='timeline-content'>
    //                   <p>
    //                     You can buy our {tokenSymbol} tokens using ETH, BTC, LTC
    //                     or USD.
    //                   </p>
    //                   <span className='timeline-content-info'>
    //                     Announcements
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className='timeline-item hidden primary'>
    //                 <div className='timeline-time'>09:31 AM</div>
    //                 <div className='timeline-content'>
    //                   <div className='chat-users'>
    //                     <div className='chat-users-stack'>
    //                       <div className='chat-avatar circle'>
    //                         <img src='images/user-a.jpg' alt='' />
    //                       </div>
    //                       <div className='chat-avatar circle'>
    //                         <img src='images/user-c.jpg' alt='' />
    //                       </div>
    //                       <div className='chat-avatar circle'>
    //                         <img src='images/user-b.jpg' alt='' />
    //                       </div>
    //                       <div className='chat-avatar circle'>
    //                         <img src='images/user-d.jpg' alt='' />
    //                       </div>
    //                     </div>
    //                     <span>+122</span>
    //                   </div>
    //                   <span className='timeline-content-info'>
    //                     New User Added
    //                   </span>
    //                 </div>
    //               </div>
    //               <div className='timeline-item hidden warning'>
    //                 <div className='timeline-time'>08:57 AM</div>
    //                 <div className='timeline-content'>
    //                   <p>
    //                     Enter your amount, you would like to contribute and
    //                     calculate the amount of token you will received. To get
    //                     tokens please make a payment. You can send payment
    //                     directly to our address or you may pay online. Once you
    //                     paid, you will receive an email about the successfull
    //                     deposit.
    //                   </p>
    //                   <span className='timeline-content-info'>
    //                     Announcements
    //                   </span>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className='timeline-load'>
    //           <a
    //             href=''
    //             className='link load-timeline'
    //             data-target='timeline-notify'
    //             data-show='2'
    //           >
    //             Load More
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Notifications;
