import React, { useEffect, useState, useRef } from "react";
import logoLight2x from "../../../assets/images/brand-a.png";
import browserRoute from "./../../../Routes/browserRoutes";
import { Link, useHistory } from "react-router-dom";
import { logout, tokenData, AirDropData } from "../../../Redux/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { tokenSymbol } from "../../TokenInfo";

const user = JSON.parse(localStorage.getItem("userDetails"));
function useOutsideAlerter(setShowDropdown, ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown({
          profile: false,
          calculator: false,
          language: false,
          time: false,
        });
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
export default function Header() {
  const dropdown = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenInfo = useSelector((state) => state.auth.tokenData);
  const contractAddress = useSelector((state) => state.auth.contractAddress);
  useEffect(() => {
    if (contractAddress !== "") {
      dispatch(tokenData(contractAddress));
      dispatch(AirDropData());
    } else if (localStorage.getItem("contractAddress")) {
      dispatch(tokenData(localStorage.getItem("contractAddress")));
      dispatch(AirDropData());
    }
  }, [contractAddress]);
  const [showDropdown, setShowDropdown] = useState({
    profile: false,
    calculator: false,
    language: false,
    time: false,
  });

  const [styleClass, setStyleClass] = useState("navbar");
  const [isDrawerActive, setIsDrawerActive] = useState(false);

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(history));
  };
  useOutsideAlerter(setShowDropdown, dropdown);
  return (
    <div className="topbar-wrap my-nav">
      <div className="topbar is-sticky">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="topbar-nav d-lg-none">
              <li className="topbar-nav-item relative">
                <a className="toggle-nav" href="#">
                  <div
                    className="toggle-icon"
                    onClick={() =>
                      isDrawerActive
                        ? (setStyleClass("navbar"), setIsDrawerActive(false))
                        : (setStyleClass("navbar active"),
                          setIsDrawerActive(true))
                    }
                  >
                    <span className="toggle-line"></span>
                    <span className="toggle-line"></span>
                    <span className="toggle-line"></span>
                    <span className="toggle-line"></span>
                  </div>
                </a>
              </li>
              {/* <!-- .topbar-nav-item --> */}
            </ul>
            {/* <!-- .topbar-nav --> */}
            <Link className="topbar-logo" to={browserRoute.DASHBOARD}>
              <img src={logoLight2x} alt="logo" />
            </Link>

            <ul className="topbar-nav">
              <li className="topbar-nav-item relative">
                <span className="user-welcome d-none d-lg-inline-block">
                  Welcome! {user ? user.name : null}
                </span>
                {showDropdown.profile === true ? (
                  <span
                    className="toggle-tigger user-thumb"
                    // href="#"
                  >
                    <em className="ti ti-user"></em>
                  </span>
                ) : (
                  <a
                    className="toggle-tigger user-thumb"
                    // href="#"
                    onClick={() => {
                      setShowDropdown({
                        profile: !showDropdown.profile,
                        calculator: false,
                        language: false,
                        time: false,
                      });
                    }}
                  >
                    <em className="ti ti-user"></em>
                  </a>
                )}
                {showDropdown.profile && (
                  <div
                    ref={dropdown}
                    id="menu"
                    className="toggle-className dropdown-content dropdown-content-right dropdown-arrow-right user-dropdown"
                  >
                    <>
                      <div className="user-status">
                        <h6 className="user-status-title">Token balance</h6>
                        <div className="user-status-balance">
                          {tokenInfo.totalSupply &&
                            (
                              tokenInfo.totalSupply - tokenInfo.tokenRaised
                            ).toFixed(3)}{" "}
                          <small>{tokenSymbol}</small>
                        </div>
                      </div>
                      <ul className="user-links">
                        <li>
                          <Link to={browserRoute.PROFILE}>
                            <i className="ti ti-id-badge"></i>My Profile
                          </Link>
                        </li>
                        <li>
                          <Link to={browserRoute.ICO_DISTRIBUTION}>
                            <i className="ti ti-infinite"></i>Referral
                          </Link>
                        </li>
                        <li>
                          <Link to={browserRoute.CREATE_TOKEN}>
                            <em className="ti ti-settings"></em>
                            Create Token
                          </Link>
                        </li>
                      </ul>
                      <ul className="user-links bg-light">
                        <li>
                          <Link to="" onClick={onLogout}>
                            <i className="ti ti-power-off"></i>Logout
                          </Link>
                        </li>
                      </ul>
                    </>
                  </div>
                )}
              </li>
              {/* <!-- .topbar-nav-item --> */}
            </ul>
            {/* <!-- .topbar-nav --> */}
          </div>
        </div>
        {/* <!-- .container --> */}
      </div>
      {/* <!-- .topbar --> */}
      <div className={styleClass}>
        <div className="container">
          <div className="navbar-innr">
            <ul className="navbar-menu">
              <li>
                <Link to={browserRoute.DASHBOARD}>
                  <em className="ikon ikon-dashboard"></em> Dashboard
                </Link>
              </li>
              <li>
                <Link to={browserRoute.USER_LIST}>
                  <em className="ikon ikon-coins"></em> User List
                </Link>
              </li>
              <li>
                <Link to={browserRoute.ICO_DISTRIBUTION}>
                  <em className="ikon ikon-distribution"></em> ICO Distribution
                </Link>
              </li>
              <li>
                <Link to={browserRoute.TRANSACTIONS}>
                  <em className="ikon ikon-transactions"></em> Transactions
                </Link>
              </li>
              <li>
                <Link to={browserRoute.BOUNTY_PROGRAM}>
                  <em className="ikon ikon-transactions"></em> Bounty Program
                </Link>
              </li>
              <li>
                <Link to={browserRoute.AIR_DROP}>
                  <em
                    className="fas fa-parachute-box mr-1"
                    style={{ fontSize: "larger" }}
                  ></em>
                  Air Drop
                </Link>
              </li>
              <li>
                <Link to={browserRoute.PROFILE}>
                  <em className="ikon ikon-user"></em> Profile
                </Link>
              </li>
            </ul>
            <ul className="navbar-btns">
              <li>
                <Link
                  to={browserRoute.KYC_LISTS}
                  className="btn btn-sm btn-outline btn-light"
                >
                  <em className="text-primary ti ti-files"></em>
                  <span>KYC Lists</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <!-- .navbar-innr --> */}
        </div>
        {/* <!-- .container --> */}
      </div>
      {/* <!-- .navbar --> */}
    </div>
  );
  {
    /* <!-- .topbar-wrap --> */
  }
}
