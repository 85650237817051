import React from "react";
import logoFox from "../../assets/images/Fox.svg";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getTokenValue } from "../../Services/profileServices";
import { tokenSymbol } from "../TokenInfo";
const TokenBalance = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const valueOfToken = auth.tokenValue;
  const tokenData = auth.tokenData;
  useEffect(() => {
    console.log("dispatch");
    dispatch(getTokenValue());
  }, []);
  return (
    <div className="token-statistics card card-token height-auto">
      <div className="card-innr">
        <div className="token-balance token-balance-with-icon">
          <div className="token-balance-icon">
            <img src={logoFox} alt="logo" />
          </div>
          <div className="token-balance-text">
            <h6 className="card-sub-title">Tokens Balance</h6>
            {tokenData.totalSupply && (
              <span className="lead">
                {(tokenData?.totalSupply - tokenData?.tokenRaised).toFixed(3)}{" "}
                <span>{tokenSymbol}</span>
              </span>
            )}
          </div>
        </div>
        <div className="token-balance token-balance-s2">
          <h6 className="card-sub-title">Your Contribution</h6>
          <ul className="token-balance-list">
            <li className="token-balance-sub">
              {tokenData.totalSupply && (
                <span className="lead">
                  {valueOfToken?.ethprice
                    ? (
                        (valueOfToken?.ethprice *
                          (tokenData?.totalSupply - tokenData?.tokenRaised)) /
                        valueOfToken?.ethprice
                      ).toFixed(3)
                    : 0}
                </span>
              )}
              <span className="sub">ETH</span>
            </li>
            <li className="token-balance-sub">
              {tokenData.totalSupply && (
                <span className="lead">
                  {valueOfToken.btcprice
                    ? (
                        (valueOfToken.btcprice * tokenData?.totalSupply) /
                        valueOfToken.ethprice
                      ).toFixed(3)
                    : 0}
                </span>
              )}
              <span className="sub">BTC</span>
            </li>
            <li className="token-balance-sub">
              {tokenData.totalSupply && (
                <span className="lead">
                  {valueOfToken.ltcprice
                    ? (
                        (valueOfToken.ltcprice * tokenData?.totalSupply) /
                        valueOfToken.ethprice
                      ).toFixed(3)
                    : 0}
                </span>
              )}
              <span className="sub">LTC</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TokenBalance;
