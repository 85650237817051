import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import withMainLayout from "../HOC/withMainLayout";
import Loader from "../Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import MaterialTable from "material-table";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { Link } from "react-router-dom";
import browserRoute from "../../Routes/browserRoutes";
import { transactionList, GetAllUsers } from "../../Redux/actions/actions";
const UserList = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data, setData] = useState({});
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  const transaction = useSelector((state) => state?.auth?.transactionLists);

  usersData?.forEach((element) => {
    let transactions = transaction
      .filter((f) => f.user === element._id)
      .map((t) => t.tokens);
    let totalTokens = transactions.reduce((value, item) => item + value, 0);

    element["tokens"] = totalTokens;
  });
  console.log("usersDatta", usersData);
  useEffect(() => {
    dispatch(GetAllUsers(setUsersData, setLoading));
  }, []);
  useEffect(() => {
    dispatch(transactionList());
  }, []);
  const columns = [
    {
      title: "USER",
      field: "user",
      render: (rowData) => (
        <div className="data-col dt-user">
          {!rowData.name && (
            <span className="lead user-name">{rowData.fName}</span>
          )}
          {rowData.name && (
            <span className="lead user-name">{rowData.name}</span>
          )}
          <span className="sub user-id">{rowData.uId}</span>
        </div>
      ),
    },
    {
      title: "EMAIL",
      field: "email",
      render: (rowData) => (
        <div className="data-col dt-email">
          <span className="sub sub-s2 sub-email">{rowData.email}</span>
        </div>
      ),
    },
    {
      title: "TOKENS",
      field: "tokens",
      render: (rowData) => (
        <div className="data-col dt-token">
          <span className="lead lead-btoken">{rowData.tokens}</span>
        </div>
      ),
    },
    {
      title: "VERIFIED STATUS",
      field: "status",
      render: (rowData) => (
        <div className="data-col dt-verify">
          <ul className="data-vr-list">
            <li>
              {rowData.is_email || rowData.kyc_status ? (
                <div className="data-state data-state-sm data-state-approved"></div>
              ) : (
                <div className="data-state data-state-sm data-state-pending"></div>
              )}
              Email
            </li>
            <li>
              <div
                style={{ borderColor: "red" }}
                className={`data-state data-state-sm data-state-${
                  rowData.kyc_status && rowData.kyc_status === "approved"
                    ? "approved"
                    : rowData.kyc_status === "rejected"
                    ? "canceled"
                    : "pending"
                }`}
              ></div>
              KYC
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "LAST LOGIN",
      field: "login",
      render: (rowData) => (
        <div>
          {" "}
          <span class="sub sub-date">{rowData.login}</span>
        </div>
      ),
    },
    {
      title: "",
      field: "",
      render: (rowData) => (
        <div className="data-col dt-status ">
          <span
            className={`dt-status-md badge badge-outline badge-${
              rowData.is_blocked ? "danger" : "success"
            } badge-md tran-btn`}
          >
            {rowData.is_blocked ? "Not Active" : "Active"}
          </span>
          <div className="float-right bg-light ">
            <span>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "20ch",
                  },
                }}
              >
                <MenuItem>
                  <a
                    data-toggle="modal"
                    data-target="#requested-details"
                    // href={`https://ropsten.etherscan.io/tx/${rowData.uId}`}
                    // onClick={() => {
                    //   setData(rowData);
                    //   handleClose();
                    // }}
                  >
                    <Link
                      to={{
                        pathname: `${browserRoute.USER_DETAILS_BTN}${rowData.uId}`,
                      }}
                    >
                      <RemoveRedEyeIcon /> {`VIEW DETAILS`}
                    </Link>
                  </a>
                </MenuItem>
                <MenuItem>
                  <span
                    data-toggle="modal"
                    data-target="#requested-details"
                    onClick={() => {
                      setData(rowData);
                      handleClose();
                    }}
                  >
                    <DoDisturbIcon /> {`SUSPEND`}
                  </span>
                </MenuItem>
                <MenuItem>
                  <span
                    data-toggle="modal"
                    data-target="#requested-details"
                    onClick={() => {
                      setData(rowData);
                      handleClose();
                    }}
                  >
                    <DeleteIcon /> {`DELETE`}
                  </span>
                </MenuItem>
              </Menu>
            </span>
          </div>
        </div>
      ),
    },
  ];

  const data1 = usersData?.map((user, key) => {
    console.log("user Id", user.id);
    return {
      uId: user._id,
      name: user.name,
      fName: user.first_name + " " + user.last_name,
      email: user.email,
      login: user.last_login ? String(user.last_login).substr(0, 16) : "",
      tokens: user?.tokens
        ? user.tokens.toString().length > 12
          ? user.tokens
              .toPrecision(45)
              .substring(0, user.tokens.toString().length - 12) + " T"
          : user.tokens.toString().length > 9
          ? user.tokens
              .toPrecision(40)
              .substring(0, user.tokens.toString().length - 9) + " B"
          : user.tokens.toString().length > 6
          ? user.tokens
              .toString()
              .substring(0, user.tokens.toString().length - 6) + " M"
          : user?.tokens
        : 0,
      is_email: user.is_email_verified,
      kyc_status: user.kyc_status,
      is_blocked: user.is_blocked,
    };
  });

  return (
    <div className="content">
      <div className="container-fluid">
        {loading ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div className="mt-2">
              <MaterialTable
                title="User List"
                columns={columns}
                data={data1}
                style={{
                  borderRadius: "15px",
                  padding: 30,
                  paddingTop: 20,
                }}
              />
            </div>
            {/* <table className="data-table dt-init user-list">
                  <TableHead />
                  <TableBody usersData={} />
                </table> */}
          </>
        )}
        {/* <!-- .card-innr --> */}

        {/* <!-- .card --> */}
      </div>
      {/* <!-- .container --> */}
    </div>
  );
};

export default withMainLayout(UserList);
