import React from "react";
import withMainLayout from "./../HOC/withMainLayout";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { transactionList } from "../../Redux/actions/actions";
import { getHoursMinutes } from "../Dashboard/RecentUsers";
import { tokenSymbol } from "../TokenInfo";
import { Box, Typography } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import AdminTable from "./AdminTable";
const Transactions = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ padding: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    // console.log("dispatch");
    dispatch(transactionList());
  }, []);

  const getTranx = useSelector((state) => state.auth.transactionLists);

  // const tranx_num = getTranx.map((data)=>data.tranx_num)
  console.log(getTranx, "tranx getting");
  return (
    <>
      <div className="page-content mt-2">
        <TabPanel value={value} index={0}>
          <AdminTable getTranx={getTranx} />
        </TabPanel>
        {/* </Box> */}
      </div>
    </>
  );
};
/* 
<>
    <div className='page-content'>
      <div className='container'>
        <div className='card content-area'>
          <div className='card-innr'>
            <div className='card-head'>
              <h4 className='card-title'>User Transactions</h4>
            </div>
            <table className='data-table dt-init user-tnx'>
              <thead>
                <tr className='data-item data-head'>
                  <th className='data-col dt-tnxno'>Tranx NO</th>
                  <th className='data-col dt-token'>Tokens</th>
                  <th className='data-col dt-amount'>Amount</th>
                  <th className='data-col dt-usd-amount'>USD Amount</th>
                  <th className='data-col dt-account'>From</th>
                  <th className='data-col dt-type'>
                    <div className='dt-type-text'>Type</div>
                  </th>
                  <th className='data-col'></th>
                </tr>
              </thead>
              <tbody>
                {getTranx.length !== 0 &&
                  getTranx.map((list) => (
                    <tr className='data-item'>
                      <td className='data-col dt-tnxno'>
                        <div className='d-flex align-items-center'>
                          <div className='data-state data-state-approved'>
                            <span className='d-none'>Pending</span>
                          </div>
                          <div className='fake-className'>
                            <span className='lead tnx-id'>
                              {'TX '}
                              {list.transaction_id?.substring(
                                4,
                                list.transaction_id.length
                              )}
                            </span>
                            <span className='sub sub-date'>
                              {new Date(list.updated_at)
                                .toString('dddd MMM yyyy')
                                .substring(0, 15)}
                              {' ' + getHoursMinutes(list.updated_at)}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className='data-col dt-token'>
                        <span className='lead token-amount'>
                          {' '}
                          {list.tokens.toPrecision(40).split('.')[0].length > 12
                            ? list.tokens
                                .toPrecision(40)
                                .substring(
                                  0,
                                  list.tokens.toString().length - 12
                                ) + ' Trillion'
                            : list.tokens.toPrecision(40).split('.')[0].length >
                              9
                            ? list.tokens
                                .toPrecision(40)
                                .substring(
                                  0,
                                  list.tokens.toString().length - 9
                                ) + ' B'
                            : list.tokens.toPrecision(40).split('.')[0].length >
                              6
                            ? list.tokens
                                .toString()
                                .substring(
                                  0,
                                  list.tokens.toString().length - 6
                                ) + ' M'
                            : list.tokens}
                        </span>
                        <span className='sub sub-symbol'>{tokenSymbol}</span>
                      </td>
                      <td className='data-col dt-amount'>
                        <span className='lead amount-pay'>
                          {list.eth.toFixed(3)}
                        </span>
                        <span className='sub sub-symbol'>
                          ETH
                          <em
                            className='fas fa-info-circle'
                            data-toggle='tooltip'
                            data-placement='bottom'
                            title={`1 ETH = 100 ${tokenSymbol}`}
                          ></em>
                        </span>
                      </td>
                      <td className='data-col dt-usd-amount'>
                        <span className='lead amount-pay'>
                          {Number(list.value_usd) ? Number(list.value_usd) : ''}
                        </span>
                        <span className='sub sub-symbol'>
                          USD
                          <em
                            className='fas fa-info-circle'
                            data-toggle='tooltip'
                            data-placement='bottom'
                            title='1 ETH = 350.54 USD'
                          ></em>
                        </span>
                      </td>
                      <td className='data-col dt-account'>
                        <span className='lead user-info'>
                          {list.wallet_from
                            ? list.wallet_from.substring(0, 5) +
                              '....' +
                              list.wallet_from.substring(37, 42)
                            : 'Not Available'}
                        </span>
                        <span className='sub sub-date'>
                          {new Date(list.transaction_time)
                            .toString('dddd MMM yyyy')
                            .substring(0, 15)}
                          {' ' + getHoursMinutes(list.transaction_time)}
                        </span>
                      </td>
                      <td className='data-col dt-type'>
                        <span
                          className='
                      dt-type-md
                      badge badge-outline badge-success badge-md tran-btn
                    '
                        >
                          Purchase
                        </span>
                      </td>
                      <td className='data-col text-right'>
                        {/* <div className="relative d-inline-block d-md-none">
                      <a
                        href="#"
                        className="btn btn-light-alt btn-xs btn-icon toggle-tigger"
                      >
                        <em className="ti ti-more-alt"></em>
                      </a>
                      <div
                        className="
                        toggle-className
                        dropdown-content dropdown-content-center-left
                        pd-2x
                      "
                      >
                        <ul className="data-action-list">
                          <li>
                            <a
                              href="#"
                              className="btn btn-auto btn-primary pay-btn"
                            >
                              <span>
                                Pay
                                <span className="d-none d-xl-inline-block">
                                  Now
                                </span>
                              </span>
                              <em className="ti ti-wallet"></em>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              className="btn btn-danger-alt btn-xs btn-icon"
                            >
                              <em className="ti ti-trash"></em>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */

// </td>
// <td className='data-col text-right'>
//   <a
//     data-toggle='tooltip'
//     data-placement='top'
//     title='view on EtherScan'
//     href={`https://ropsten.etherscan.io/tx/${list.transaction_hash}`}
//     target='_blank'
//     // data-toggle="modal"
//     // data-target="#transaction-details"
//     className='btn btn-sm btn-icon'
//   >
//     <em
//       style={{ color: 'grey' }}
//       className='fab fa-ethereum'
//     ></em>
//   </a>
{
  /* <Link
                          to={browserRoute.TRANSACTION_DETAILS}
                          // data-toggle="modal"
                          // data-target="#transaction-details"
                          className="btn btn-light-alt btn-xs btn-icon"
                        >
                          <em className="ti ti-eye"></em>
                        </Link> */
}
{
  /* </td> */
}
{
  /* </tr> */
}
{
  /* ))} */
}
{
  /* <!-- .data-item --> */
}

{
  /* <!-- .data-item --> */
}

{
  /* <!-- .data-item --> */
}
{
  /* </tbody> */
}
{
  /* </table> */
}
{
  /* </div> */
}
{
  /* <!-- .card-innr --> */
}
{
  /* </div> */
}
{
  /* <!-- .card --> */
}
{
  /* </div> */
}
{
  /* <!-- .container --> */
}
{
  /* </div> */
}

export default withMainLayout(Transactions);
