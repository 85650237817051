import react, { useState } from "react";
import { Link } from "react-router-dom";
import browserRoute from "../../Routes/browserRoutes";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
function DropDown({ item }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div>
        <IconButton
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <em className="ti ti-more-alt"></em>
        </IconButton>

        <Menu
          transformOrigin={{ horizontal: "right", vertical: "center" }}
          anchorOrigin={{ horizontal: "left", vertical: "center" }}
          className="list-group"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div className="dropdown-content-top-left">
            <ul className="dropdown-list">
              <li>
                <Link to={`${browserRoute.USER_DETAIL_BTN}${1}`}>
                  <em className="ti ti-eye"></em> View Details
                </Link>
              </li>
              <li>
                <a href="#">
                  <em className="ti ti-na"></em> Suspend
                </a>
              </li>
              <li>
                <a href="#">
                  <em className="ti ti-trash"></em> Delete
                </a>
              </li>
            </ul>
          </div>
        </Menu>
      </div>
    </>
  );
}

export default DropDown;
