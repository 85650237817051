import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';

// import DetailModal from "./detailModal";
import { getHoursMinutes } from '../Dashboard/RecentUsers';
import { tokenSymbol } from '../TokenInfo/index';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { BtnLoading } from '../Loader/BtnLoading';
import { Link } from 'react-router-dom';
import browserRoute from '../../Routes/browserRoutes';
const AdminTable = ({ getTranx }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;

  const columns = [
    {
      title: 'TRANX NO',
      field: 'TranxNo',
      render: (rowData) => (
        <div className="data-col dt-tnxno ">
          <div class="d-flex align-items-center">
            <div class="data-state data-state-pending">
              <span class="d-none">Pending</span>
            </div>
            <div class="fake-class">
              <span class="lead tnx-id">TNX {rowData.TranxNo}</span>
              <span class="sub sub-date">{rowData.updated_at}</span>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'TOKENS',
      field: 'tokens',
      render: (rowData) => (
        <div class="data-col dt-token ml-3">
          <span class="lead token-amount">{rowData.tokens}</span>
          <span class="sub sub-symbol">{tokenSymbol}</span>
        </div>
      ),
    },
    {
      title: 'AMOUNT',
      field: 'amount',
      render: (rowData) => (
        <div class="data-col dt-amount ml-3">
          <span class="lead amount-pay">{rowData.eth}</span>
          <span class="sub sub-symbol">
            ETH{' '}
            <em
              class="fas fa-info-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title={`1 ETH = 100 ${tokenSymbol}`}
            ></em>
          </span>
        </div>
      ),
    },
    {
      title: 'USD AMOUNT',
      field: 'UsdAmount',
      render: (rowData) => (
        <div class="data-col dt-usd-amount">
          <span class="lead amount-pay"> {rowData.usd}</span>
          <span class="sub sub-symbol">
            USD{' '}
            <em
              class="fas fa-info-circle"
              data-toggle="tooltip"
              data-placement="bottom"
              title="1 ETH = 100 FT"
            ></em>
          </span>
        </div>
      ),
    },
    {
      title: 'FROM',
      field: 'from',
      render: (rowData) => (
        <div class="data-col dt-account">
          <span class="lead user-info">{rowData.wallet}</span>
          <span class="sub sub-date">
            {rowData.transaction_time} {rowData.transaction_hour}
          </span>
        </div>
      ),
    },
    {
      title: 'TYPE',
      field: 'url',
      render: (rowData) => (
        <div>
          <span className="dt-status-md badge badge-outline badge-success badge-md b-button-hovereffect">
            PURCHASE
          </span>
          <div className="float-right bg-light">
            <span>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >
                <MenuItem>
                  <a
                    data-toggle="modal"
                    data-target="#requested-details"
                    href={`https://ropsten.etherscan.io/tx/${rowData.hash}`}
                    // onClick={() => {
                    //   setData(rowData);
                    //   handleClose();
                    // }}
                  >
                    <Link to={browserRoute.TRANSACTION_DETAILS}>
                      <RemoveRedEyeIcon /> {`View Details`}
                    </Link>
                  </a>
                </MenuItem>
                <MenuItem>
                  <span
                    data-toggle="modal"
                    data-target="#requested-details"
                    onClick={() => {
                      setData(rowData);
                      handleClose();
                    }}
                  >
                    <CheckBoxOutlinedIcon /> {`Approve`}
                  </span>
                </MenuItem>
                <MenuItem>
                  <span
                    data-toggle="modal"
                    data-target="#requested-details"
                    onClick={() => {
                      setData(rowData);
                      handleClose();
                    }}
                  >
                    <DoDisturbIcon /> {`Cancel`}
                  </span>
                </MenuItem>
                <MenuItem>
                  <span
                    data-toggle="modal"
                    data-target="#requested-details"
                    onClick={() => {
                      setData(rowData);
                      handleClose();
                    }}
                  >
                    <DeleteIcon /> {`Delete`}
                  </span>
                </MenuItem>
              </Menu>
            </span>
          </div>
          {/* <span
            className="dt-status-md badge badge-outline b-button-userlist badge-success badge-md"
            onClick={() => reject(rowData.id)}
          >
            {loading ? "...loading" : rejectbtnText}
          </span> */}
          {/* {!loading && ( */}

          {/* //   onClick={() => setData(rowData)}
            //   data-toggle="modal"
            //   data-target="#requested-details"
            //   className="iconBtn m-1 icon"
            // >
            //   <em className="ti ti-eye"></em>
            // </span> */}
        </div>
      ),
    },
  ];

  const data1 = getTranx.map((list) => {
    return {
      TranxNo: list.transaction_id?.substring(4, list.transaction_id.length),
      updated_at:
        new Date().toString('dddd MMM yyyy').substring(0, 15) +
        ' ' +
        getHoursMinutes(list.updated_at),
      tokens:
        list?.tokens.toPrecision(40).split('.')[0].length > 12
          ? list.tokens
              .toPrecision(40)
              .substring(0, list.tokens.toString().length - 12) + ' Trillion'
          : list.tokens.toPrecision(40).split('.')[0].length > 9
          ? list.tokens
              .toPrecision(40)
              .substring(0, list.tokens.toString().length - 9) + ' B'
          : list.tokens.toPrecision(40).split('.')[0].length > 6
          ? list.tokens
              .toString()
              .substring(0, list.tokens.toString().length - 6) + ' M'
          : list.tokens,
      eth: list.eth.toFixed(3),
      usd: Number(list.value_usd) ? Number(list.value_usd) : '',
      wallet: list.wallet_from
        ? list.wallet_from.substring(0, 5) +
          '....' +
          list.wallet_from.substring(37, 42)
        : 'Not Available',
      transaction_time: new Date(list.transaction_time)
        .toString('dddd MMM yyyy')
        .substring(0, 15),
      transaction_hour: ' ' + getHoursMinutes(list.transaction_time),
      hash: list.transaction_hash,
    };
  });

  return (
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12" style={{ borderRadius: '15px' }}>
            <MaterialTable
              title={
                <div style={{ fontSize: '1.3rem', fontWeight: '600' }}>
                  Admin Transactions
                </div>
              }
              style={{ borderRadius: '15px', padding: 30, paddingTop: 20 }}
              columns={columns}
              data={data1}
              options={{
                // search:
                // showTitle: true,
                headerStyle: {
                  color: '#1E375E',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '14px',
                  borderRadius: '15px',
                  tableLayout: 'auto',
                },
                pageSize: 12,
                //  padding: 312,
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminTable;
